import React, { Component } from "react";
import Link from 'next/link';
import { APP_GENERAL_STATE } from "../../../store/ActionTypes";
import { Badge } from "antd";
import { ChooseFromTwoModal } from "../../common";
import { redirect } from "../../../utils/redirect";

class AppNav extends Component {
    state = {
        navigation: false,
        smallDevice: null,
        extraSmallDevice: null,
        createJobAlert: false,
    };

    constructor(props) {
        super(props);
        this.myNav = React.createRef();
    }

    /**
     * routeName2 is for the My Account not being visible on the company details page
     **/
    getLoginNavButton(routeName, routeName2) {
        if (routeName2 === 'detail') {
            routeName += '/' + routeName2;
        }
        const { user, company } = this.props.reduxStore.getState();
        const image = user.data.ica === true ? company.item.company.logo : user.data.image;
        const name = user.data.ica === true ? company.item.company.name : user.data.full_name;
        if (this.state.extraSmallDevice === true)
            return (<ul className={'auth-nav extra-option'}>
                <li style={{ paddingRight: 10 }}>
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                            props: 'searchModal',
                            value: !this.props.app.searchModal
                        });
                    }}>
                        <i style={{ fontSize: 20 }} className="fas fa-search fa-2x" />
                    </a>
                </li>

                {user.authCheck === false ?
                    <li>
                        <Link href={'/auth/login'}>
                            <a href="/auth/login">
                                <i className={'fas fa-sign-in-alt'} /> SIGN IN
                            </a>
                        </Link>
                    </li>
                    :
                    <li>
                        <Link href={'/user'}>
                            <a href="/user" style={{ verticalAlign: 'none' }}>
                                <img className={'user_profile_image_nav'} src={image} alt={name} />
                            </a>
                        </Link>
                    </li>}
            </ul>);
        else if (this.state.extraSmallDevice === false) {

            if (user.authCheck === false) {
                return (<ul className={'auth-nav'}>
                    <li>
                        <Link href={'/auth/login'}>
                            <a href="/auth/login">
                                <i className={'fas fa-sign-in-alt'} /> SIGN IN
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href={'/auth/register'}>
                            <a href="/auth/register">
                                <i className={'fas fa-user-plus'} /> REGISTER
                            </a>
                        </Link>
                    </li>
                </ul>);
            } else if (user.authCheck === true && (!(['user', 'company'].includes(routeName)))) {
                return (<ul className={'auth-nav'}>
                    <li>
                        <Link href={'/user'}>
                            <a href="/user" className={'user_profile_nav'}>
                                <img className={'user_profile_image_nav'} src={image}
                                    alt={name} />
                                &nbsp;&nbsp;<span>My Account</span>
                            </a>
                        </Link>
                    </li>
                </ul>)
            }
        }

    }

    render() {
        const routeName = this.props.router.route.split('/')[1] || '';

        const { isAuthPage, user } = this.props;
        return (
            <header className={'app-header'}>
                <div className="container no-padding">

                    <div className={"main-navigation huge_device_navigation"}>

                        <div style={{ paddingLeft: 20 }} className="logo-container">
                            <Link href={'/'}><a href={''}><img src="https://rojal.s3.ap-south-1.amazonaws.com/rojal/rojal_logo.png" alt="" /></a></Link>
                        </div>
                        <div className="nav-items">
                            <ul className={'big-device-navigation'}>
                                <li>
                                    <Link href={'/vacancies/[[...slug]]'} as={'/vacancies/'}>
                                        <a href="/vacancies" className={routeName === 'vacancies' ? 'active' : ''}>
                                            VACANCIES
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link passHref href={'/employee'}>
                                        <a href="/employee" className={routeName === 'employee' ? 'active' : ''}>
                                            FIND EMPLOYEE
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link passHref href={'/companies'}>
                                        <a href="/companies"
                                            className={routeName === 'companies' ? 'active' : ''}>FOLLOW COMPANIES</a>
                                    </Link>
                                </li>
                                {(user.authCheck && user.data.ica === false) ? null :
                                    (user.authCheck ? <li>
                                        <Link passHref href={'/company/post-job'}>
                                            <Badge count={'Free'} className={'free_vacancies'}>
                                                <a href="/employee"
                                                    className={this.props.router.route === '/company/post-job' ? 'active' : ''}>
                                                    POST VACANCIES
                                                </a>
                                            </Badge>
                                        </Link>
                                    </li> : <li>
                                        <Badge count={'Free'} className={'free_vacancies'}>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ createJobAlert: true });
                                            }} href=""
                                                className={this.props.router.route === '/job/create' ? 'active' : ''}>
                                                POST VACANCIES
                                            </a>
                                        </Badge>
                                    </li>)

                                }
                            </ul>
                            {this.getLoginNavButton(routeName, this.props.router.route.split('/')[2])}
                        </div>
                    </div>


                    <div ref={this.myNav} className="main-navigation small-device-navigation mobile_nav">
                        <div className="nav-items small-device-nav-item">
                            <ul className={this.state.navigation ? 'show' : 'show'}>
                                <li>
                                    <Link href={'/vacancies/[[...slug]]'} as={'/vacancies/'}>
                                        <a href="/vacancies" className={routeName === 'vacancies' ? 'active' : ''}>
                                            <i className="far fa-newspaper" />
                                            VACANCIES
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/employee'}>
                                        <a href="/employee" className={routeName === 'employee' ? 'active' : ''}>
                                            <i className="fas fa-user-tie" />
                                            EMPLOYEES
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/companies'}>
                                        <a href="/companies" className={routeName === 'companies' ? 'active' : ''}>
                                            <i className="fas fa-building" />
                                            COMPANIES
                                        </a>
                                    </Link>
                                </li>


                                {routeName.length === 0 ? <li className={'category_sm_d'}>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showCategorySideBar',
                                            value: !this.props.app.showCategorySideBar
                                        });
                                        // this.props.
                                    }} className={this.props.app.showCategorySideBar ? 'active' : ''}>
                                        <i className="fas fa-bars" />
                                        CATEGORY
                                    </a>
                                </li> : null}

                                {routeName === 'employee' ? <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showEmployeeFilter',
                                            value: !this.props.app.showEmployeeFilter
                                        });
                                        // this.props.
                                    }} className={this.props.app.showEmployeeFilter ? 'active' : ''}>
                                        <i className="fas fa-sliders-h" />
                                        FILTER
                                    </a>
                                </li> : null}


                                {routeName === 'vacancies' ? <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showJobFilter',
                                            value: !this.props.app.showJobFilter
                                        });
                                        // this.props.
                                    }} className={this.props.app.showJobFilter ? 'active' : ''}>
                                        <i className="fas fa-sliders-h" />
                                        FILTER
                                    </a>
                                </li> : null}
                                {routeName === 'blogs' ? <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showBlogFilter',
                                            value: !this.props.app.showBlogFilter
                                        });
                                        // this.props.
                                    }} className={this.props.app.showBlogFilter ? 'active' : ''}>
                                        <i className="fas fa-sliders-h" />
                                        FILTER
                                    </a>
                                </li> : null}
                                {routeName === 'companies' ? <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showCompanyFilter',
                                            value: !this.props.app.showCompanyFilter
                                        });
                                        // this.props.
                                    }} className={this.props.app.showCompanyFilter ? 'active' : ''}>
                                        <i className="fas fa-sliders-h" />
                                        FILTER
                                    </a>
                                </li> : null}

                                {isAuthPage ? <li>
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateStateUpdate(APP_GENERAL_STATE, {
                                            props: 'showAuthNav',
                                            value: !this.props.app.showAuthNav
                                        });
                                        // this.props.
                                    }} className={this.props.app.showAuthNav ? 'active' : ''}>
                                        <i className="fas fa-bars" />
                                        MENU
                                    </a>
                                </li> : null}

                            </ul>
                        </div>
                    </div>


                </div>

                <ChooseFromTwoModal
                    optionFirst={{
                        title: 'Login/Register',
                        info: 'Login or register an account before posting a job.',
                        icon: 'user',
                        tooltipText: "Login to your company account or register a new if you don't have one, verify it then post a job for that account",
                        onClick: () => {
                            this.setState({ createJobAlert: false });
                            redirect('/auth/login');
                        }
                    }}
                    optionSecond={{
                        title: 'Create Job',
                        info: 'Create a job first, register an account later',
                        icon: 'double-right',
                        tooltipText: "Create a job as guest user without registering an account. Register an account later to publish the job",
                        onClick: () => {
                            this.setState({ createJobAlert: false });
                            redirect('/job/create')
                        }
                    }}
                    subTitle={'(Guest)'}
                    title={"Not Logged In"}
                    showModal={this.state.createJobAlert}
                    onClose={() => {
                        this.setState({ createJobAlert: false });
                    }} />
            </header>
        );
    }


    componentDidMount() {
        this.setState({ smallDevice: (window.screen.width < 992 && window.screen.width > 768) });
        this.setState({ extraSmallDevice: (window.screen.width < 769) });

        let scrollPosition = 0;
        let nav = this.myNav;
        let fixNav = document.querySelector('.small-device-nav-item');

        if ((window.screen.width < 769))
            window.addEventListener('scroll', function () {
                if (nav.current) {
                    const { top, left } = nav.current.getBoundingClientRect();
                    if (top > scrollPosition) {
                        if (top > 0) {
                            fixNav.style.position = 'static';
                            fixNav.style.borderTop = 'none';
                        }
                    } else {
                        if (top < 0) {
                            fixNav.style.position = 'fixed';
                            fixNav.style.top = '0px';
                            fixNav.style.left = '0px';
                            fixNav.style.borderTop = '20px solid rgba(0, 0, 0, 0.04)';
                        }
                    }
                    scrollPosition = top;
                }
            }, true);
    }
}

export { AppNav };