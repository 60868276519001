import React, {Component} from 'react';
import {NotFound} from "../../common/NotFound";
import PropTypes from 'prop-types';
import {JobGrid} from "../../common";

class Jobs extends Component {
    render() {
        let {data, column} = this.props;
        if (!column) {
            column = 'col-lg-4'
        }
        if (!data.length)
            return <NotFound/>;

        return (<div className="container">
            <div className="row">
                {data.map((item, i) => {
                    return (<div key={i} className={`col-md-6 ${column}`}>
                        <JobGrid {...this.props} item={item}/>
                    </div>);
                })}
            </div>
        </div>);
    }
}


Jobs.propTypes = {
    data: PropTypes.array.isRequired
};

export {Jobs};