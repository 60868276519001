import React, { Component } from 'react';
import { Button, Checkbox, Dropdown, Icon, Input, Popover, Menu, Modal } from "antd";
import PropTypes from 'prop-types';
import { VError } from './../../../../src/components/common'
import Link from "next/link";
import { ucWords } from "../../../utils/helpers";
// import {Radio} from 'antd';
import Router from 'next/router';

class RegisterForm extends Component {
    state = {
        accountType: 'company',
        passwordShow: false
    };

    renderDropDown() {
        const { inputChange } = this.props;

        return (<Menu visible={true}>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={(e) => {
                    e.preventDefault();
                    inputChange({ props: 'is_company_account', value: false, type: 'signUp' });
                    //$this.setState({accountType: 'personal'})
                }} href="">
                    Personal
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={(e) => {
                    e.preventDefault();
                    inputChange({ props: 'is_company_account', value: true, type: 'signUp' });
                    //$this.setState({accountType: "company"})
                }} href="">
                    Company
                </a>
            </Menu.Item>
        </Menu>);
    }


    render() {
        const { signUpState, inputChange, signUpAction, authScreenSwitch, query } = this.props;

        return (
            <div>
                <div>
                    <h1>Register your <Popover className={'z_index_pop'} defaultVisible={true} content={
                        (<div>
                            <p>Switch Company or Personal Account.</p>
                        </div>)}>
                        <Dropdown overlay={this.renderDropDown()}>
                            <a className="ant-dropdown-link" href="#">
                                {signUpState.is_company_account ? 'company' : 'personal'}
                                {/*<Icon style={{fontSize:24,verticalAlign:0}} type="down" />*/}
                            </a>
                        </Dropdown>
                    </Popover> account.</h1>
                    <p>We have waited so long to have you among us. At last, the time has come. We are most delightfully
                        welcoming you to join us today! <i className="fa fa-info-circle" /></p>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault();
                    signUpAction()
                }} method="post" action="" autoComplete="off">

                    <Input
                        placeholder={!signUpState.is_company_account ? "Full name" : "Company Name"}
                        prefix={<Icon type={!signUpState.is_company_account ? "user" : "home"}
                            style={{ color: 'rgba(0,0,0,.25)' }} />}
                        value={ucWords(signUpState.full_name)}
                        onChange={(e) => {
                            inputChange({ props: 'full_name', value: e.target.value, type: 'signUp' });
                        }}
                    />
                    <VError eFor={'full_name'} errors={signUpState.errors} />
                    <br />
                    <br />
                    <br />

                    <Input
                        placeholder="Enter your email"
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        value={signUpState.email}
                        onChange={(e) => {
                            inputChange({ props: 'email', value: e.target.value, type: 'signUp' });
                        }}
                    />
                    <VError eFor={'email'} errors={signUpState.errors} />

                    <br />
                    <br />
                    <br />
                    <Input
                        disabled={query.type === 'force_admin' && signUpState.is_company_account}
                        type={this.state.passwordShow ? 'text' : "password"}
                        placeholder="Password"
                        prefix={<Icon type="unlock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        suffix={
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                this.setState({ passwordShow: !this.state.passwordShow })
                            }}><Icon
                                    type={this.state.passwordShow ? "eye-invisible" : "eye"}
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                /></a>
                        }
                        value={signUpState.password}
                        onChange={(e) => {
                            inputChange({ props: 'password', value: e.target.value, type: 'signUp' });
                        }}
                    />
                    <VError eFor={'password'} errors={signUpState.errors} />

                    <br />
                    <br />
                    <p><Checkbox checked={signUpState.agreement} onChange={() => {
                        inputChange({
                            props: 'agreement',
                            value: !signUpState.agreement,
                            type: 'signUp'
                        });
                    }}>I have read and agree with the <a href="/terms-conditions" target={'_blank'}><u>Terms and
                        conditions.</u></a></Checkbox></p>
                    <br />
                    <Button block type="primary" loading={signUpState.loader} disabled={!signUpState.agreement}
                        htmlType={'submit'}>Register</Button>

                </form>
                <div className="login-extra-option">
                    <div className="more-links">
                        <p>Already have an account? {!authScreenSwitch ?
                            <Link href={'/auth/login'}><a href="">Log in.</a></Link> :
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                authScreenSwitch();
                            }}>Log in.</a>}
                        </p>
                    </div>
                </div>
                {this.renderAccountTypeModal()}
            </div>
        );
    }

    componentWillUnmount() {
        // this.props.inputChange({ props: 'showAccountSelector', value: false });
        this.props.inputChange({ props: 'is_company_account', value: null, type: 'signUp' });
    }

    componentWillMount(){
        
    }

    renderAccountTypeModal() {
        return (
            <div className="my_modal">
                <Modal
                    visible={this.props.showAccountSelector === true && this.props.signUpState.is_company_account === null}
                    title="Title"
                    className={'account_type_modal'}
                    onOk={() => {
                    }}
                    onCancel={() => {
                        Router.back();
                    }}
                    footer={[]}>
                    <br />
                    <br />
                    <br />

                    <div className="acc_label">
                        <p>Select Account Type</p>
                        <p>Register Account </p>
                    </div>
                    <div className="acc_selector">
                        <div onClick={() => {
                            this.props.inputChange({ props: 'is_company_account', value: true, type: 'signUp' });
                            // this.setState({ showAccountSelector: false });
                        }} className="personal select_box">
                            <Icon type="shop" />
                            <p>Company</p>
                            <small>Post Job / Search Employee</small>
                        </div>
                        <div onClick={() => {
                            this.props.inputChange({ props: 'is_company_account', value: false, type: 'signUp' });
                        }} className="company select_box">
                            <Icon type="user" />
                            <p>Personal</p>
                            <small>Search Job / Follow company</small>
                        </div>

                    </div>
                    <br />
                    <br />
                    <br />
                </Modal>
            </div>
        );
    }
}


RegisterForm.propTypes = {
    signUpState: PropTypes.object.isRequired,
    inputChange: PropTypes.func.isRequired,
    signUpAction: PropTypes.func.isRequired
};


export { RegisterForm }
