import React, {Component} from 'react';
import {connect} from 'react-redux';
import {applyJob, applyModalClose} from "../../../../src/store/actions";
import {Button, Modal, Input} from "antd";

const {TextArea} = Input;

class ApplyModal extends Component {
    state = {
        declarations: '',
        loading: false,
    };

    render() {
        const {job, applyJob, applyModalClose, user} = this.props;
        return (<Modal
            className={'auth-modal ' + (!user.data.irp ? 'modal-error' : '')}
            visible={job.apply.modal}
            onCancel={() => {
                this.setState({visible: false})
            }}
            okText="Apply"
            cancelText="Cancel">
            <div className="auth-container-x" style={{padding: '40px 0 0 0'}}>
                <h3 style={{fontSize: 18, fontWeight: 'bold', color: '#777'}}> Apply Job
                    ({job.apply.selectedTitle})</h3>
                <p style={{width: '80%', marginTop: 0}}>
                    Are you sure you want to apply for this post? Your CV
                    will be forwarded to the company
                </p>

                {!user.data.irp ?
                    <p style={{fontSize: 12, fontWeight: 'bold', marginTop: 20, color: '#dc3545'}}>Looks like your
                        resume is hidden, however you can still send your application.</p> : null}
                <TextArea
                    value={this.state.declarations}
                    onChange={(e) => {
                        this.setState({declarations: e.target.value})
                    }}
                    style={{marginTop: 20}}
                    placeholder="Declaration you want to send along with your CV."
                />
                <div className="button-container" style={{textAlign: "right", marginTop: 20}}>
                    <Button onClick={() => {
                        applyModalClose();
                    }}>Cancel</Button>
                    <Button loading={this.state.loading} onClick={() => {
                        this.setState({loading: true});
                        applyJob(this.state.declarations).then(res => {
                            this.setState({loading: false});
                            this.setState({declarations: ''});
                        }).finally(() => {
                            this.setState({declarations: ''});
                        });
                    }} type="primary" style={{marginLeft: 15}}>Apply</Button>
                </div>
            </div>
        </Modal>);
    }
}


const mapStateToProps = state => {
    return {
        job: state.job,
        user: state.user
    }
};

export default connect(mapStateToProps, {applyJob, applyModalClose})(ApplyModal);
