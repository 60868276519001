import Link from 'next/link'
import React from "react";
import { dashboardNavigationItems } from "../../../utils/helpers";
import { APP_GENERAL_STATE } from "../../../store/ActionTypes";
import { Badge, Divider, Icon, Modal, Tag } from "antd";
import { redirect } from "../../../utils/redirect";
import { openNotificationWithIcon } from "../../../utils/notification";

const { confirm } = Modal;

const renderNavigation = (props, navItems) => {
    const { route } = props.router;
    const { data } = props.user;
    const { downloadResume, downloadSampleResume, generateStateUpdate } = props;

    return (
        <div className="tr-sidebar">

            <ul className="nav nav-tabs profile-tabs section" role="tablist">
                {navItems.map(function (item, i) {
                    return (<li key={i} role="presentation" className={route === item.route ? "active" : ''}>
                        <Link href={item.route}>
                            <a href="" onClick={() => {
                                item.action ? item.action() : null;
                                generateStateUpdate(APP_GENERAL_STATE, { props: 'showAuthNav', value: false });
                            }} aria-controls="account-info"
                                role="tab" data-toggle="tab">
                                <Badge dot title={'4 Pending Job Posts at draft'} count={item.route === '/company/job-posts' && route !== item.route ? 4 : 0}>
                                    <span>

                                        <i className={item.icon} aria-hidden="true" />
                                        {item.name}
                                    </span>
                                </Badge>


                            </a>
                        </Link>
                    </li>
                    );
                })}
            </ul>
            {!data.ica ?
                <div>
                    <a onClick={(e) => {
                        e.preventDefault();
                        downloadResume({ userId: data.id }).then((res) => {
                            // window.open(process.env.DOWNLOADPATH + `/cv/${data.id}/download`, '_blank');
                        }).catch(err => {
                            openNotificationWithIcon('error', {
                                title: 'Download Failed',
                                description: err.response.data.msg
                            });
                            const con = confirm({
                                title: 'Oops!! Download not ready.',
                                content: <div><p>Your resume requires few information ready before you download it.
                                    Please make sure all the required information are filled.</p>
                                    <Divider dashed={true} orientation="left"
                                        style={{ marginTop: 10, marginBottom: 20 }}>
                                        <span style={{ color: '#777', fontSize: 14 }}>Required Fields</span></Divider>

                                    <Tag style={{ borderStyle: 'dashed' }}>Full Name</Tag><Tag
                                        style={{ borderStyle: 'dashed' }}>Email</Tag><Tag
                                            style={{ borderStyle: 'dashed' }}>Expertise Field</Tag>
                                    <Tag
                                        style={{ borderStyle: 'dashed' }}>Phone</Tag><Tag
                                            style={{ borderStyle: 'dashed' }}>Address (Permanent/Temporary)</Tag><Tag
                                                style={{ borderStyle: 'dashed' }}>Career Objectives</Tag><Tag
                                                    style={{ borderStyle: 'dashed' }}>Declaration</Tag>

                                    <Divider dashed={true} orientation="left"
                                        style={{ marginTop: 10, marginBottom: 20 }}>
                                        <span style={{ color: '#777', fontSize: 14 }}>Optional Fields</span></Divider>
                                    <Tag style={{ borderStyle: 'dashed' }}>DOB</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Gender</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Salary Expectation</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Nationality</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Academic Qualifications</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Experiences</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Special Skills</Tag>
                                    <Tag style={{ borderStyle: 'dashed' }}>Languages</Tag>

                                    <p style={{ fontSize: 12, marginTop: 30, marginBottom: 20 }}><a
                                        rel="noreferrer" target={'_blank'}
                                        onClick={(e) => {
                                            downloadSampleResume(true);
                                            e.preventDefault();
                                        }}
                                        href={'#'}><Icon
                                            type="cloud-download" /> Preview Sample Resume</a></p>
                                </div>,
                                okText: 'Update Info Now',
                                cancelText: 'Update Later',
                                onOk() {
                                    redirect('/user/update-resume');
                                }
                            });
                        });
                    }} href={process.env.DOWNLOADPATH + `/cv/${data.id}/download`}
                        className="btn btn-primary">
                        <i className="fa fa-cloud-download"
                            aria-hidden="true" /> <span>Download Resume</span>
                    </a>
                </div> : null}
        </div>

    );
};


const DashboardNav = (props) => {
    const { data } = props.user;
    return <div className="col-md-3 hidden-sm">{renderNavigation(props, dashboardNavigationItems(data.ica))}</div>;
};

const DashboardDrawerNav = (props) => {

    const { data } = props.user;
    return <div className="drawer_nav">{renderNavigation(props, dashboardNavigationItems(data.ica))}</div>;
};

export { DashboardNav, DashboardDrawerNav };