import React, {Component} from 'react';
import {UserCard} from "./UserCard";

class HireMe extends Component {
    render() {
        const {users, openUserDetails} = this.props;
        return (<div className="hire-me-section">
                <div className="section-content-box">
                    <div className="container">
                        <div className="row">
                            <UserCard openUserDetails={openUserDetails} isMine={false} users={users}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export {HireMe};