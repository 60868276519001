import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Input } from "antd";
import { VError } from "./../../../../src/components/common";
import Link from "next/link";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';


class LoginForm extends Component {
    state = {
        passwordShow: false
    };

    render() {
        const { loginState, inputChange, signInAction, redirectToSuccess, authScreenSwitch, signInActionSocial, authModelSwitch, customMessage, isIntruder } = this.props;
        return (
            <div>
                {!isIntruder ? <h1>What's your login info?</h1> : <h1>Please login first!!</h1>}
                {!customMessage ?
                    <p>
                        Our heartiest welcome goes to you. Thanks for being a proud member of our unique and
                        ever-expanding
                        line of products! We’re honored to have you with us always!
                    </p> : <p dangerouslySetInnerHTML={{ __html: customMessage }} className={'custom_auth_msg'} />}

                <form
                    onSubmit={e => {
                        e.preventDefault();
                        signInAction(redirectToSuccess);
                    }}
                    method="post"
                    autoComplete="off">
                    <Input
                        placeholder="Enter your email"
                        prefix={
                            <Icon
                                type="user"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        value={loginState.email}
                        onChange={e => {
                            inputChange({
                                props: "email",
                                value: e.target.value
                            });
                        }} />
                    <VError eFor={"email"} errors={loginState.errors} />

                    <br />
                    <br />
                    <br />

                    <Input
                        type={this.state.passwordShow ? 'text' : "password"}
                        placeholder="Password"
                        prefix={
                            <Icon
                                type="unlock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                            />
                        }
                        suffix={
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                this.setState({ passwordShow: !this.state.passwordShow })
                            }}><Icon
                                    type={this.state.passwordShow ? "eye-invisible" : "eye"}
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                /></a>
                        }
                        value={loginState.password}
                        onChange={e => {
                            inputChange({
                                props: "password",
                                value: e.target.value
                            });
                        }} />
                    <VError eFor={"password"} errors={loginState.errors} />
                    <br />
                    <br />
                    <br />
                    <Button
                        block
                        type="primary"
                        loading={loginState.loader}
                        htmlType={"submit"}>
                        Log in
                    </Button>
                </form>

                <div className="login-extra-option">
                    <h3>Or</h3>
                    <p className="alternate-login">
                        you can sign in to your personal account with &nbsp;&nbsp;
                        <span className={'social_login_option'}>
                            <FacebookLogin
                                appId="2234768930107771"
                                autoLoad={false}
                                callback={(res) => {
                                    signInActionSocial('facebook', res.accessToken);
                                }}
                                render={renderProps => (
                                    <a href="" onClick={(e) => {
                                        e.preventDefault();
                                        renderProps.onClick()

                                    }} className="facebook">
                                        <i className="fab fa-facebook-f" />acebook
                                    </a>
                                )}>
                            </FacebookLogin>

                            &nbsp;&nbsp;or&nbsp;&nbsp;{" "}
                            <GoogleLogin
                                clientId="384102561533-262ujrf5fbjctq9dnuvo0726p9i56ntl.apps.googleusercontent.com"
                                buttonText="Login"
                                autoLoad={false}
                                render={renderProps => (
                                    <a href="" onClick={(e) => {
                                        e.preventDefault();
                                        renderProps.onClick();
                                    }} className="google">
                                        <i className="fab fa-google" />oogle</a>
                                )}
                                onSuccess={(res) => {
                                    signInActionSocial('google', res.profileObj);
                                }}
                                onFailure={(err) => {
                                }}>
                            </GoogleLogin>
                        </span>
                    </p>
                    <div className="more-links">
                        {/*{!authScreenSwitch ? <Link href={"/auth/register"}>*/}
                        {/*<a href="">Register</a>*/}
                        {/*</Link> : <a href="" onClick={(e) => {*/}
                        {/*e.preventDefault();*/}
                        {/*authScreenSwitch()*/}
                        {/*}}>Register</a>}*/}
                        <Link href={"/auth/register"}>
                            <a href="" onClick={(e) => {
                                if (authScreenSwitch) {
                                    // e.preventDefault();
                                    authModelSwitch()
                                }
                            }}>Register</a>
                        </Link>

                        {!authModelSwitch ? <Link href={"/auth/reset"}>
                            <a href="">Forgot Passowrd</a>
                        </Link> : <Link href={"/auth/reset"}><a href="" onClick={(e) => {
                            // e.preventDefault();
                            authModelSwitch()
                        }}>Forgot Password</a></Link>}

                    </div>
                </div>
            </div>
        );
    }
}

LoginForm.propTypes = {
    loginState: PropTypes.object.isRequired,
    inputChange: PropTypes.func.isRequired,
    signInAction: PropTypes.func.isRequired,
    redirectToSuccess: PropTypes.bool,
    isItruder: PropTypes.bool,
    // customMessage: PropTypes.elementType
};


export { LoginForm };