import React, { useState } from "react";
import Link from "next/link";
import { Input } from 'antd';
const { Search } = Input;
import { useDispatch } from "react-redux";
import { fetchAllBlog } from "../../../store/actions";

import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";


const CategoryLoop = ({ router, categories }) => {
    return <ul>
        <li>
            <i className="fas fa-caret-right"></i>
            <Link href={`/blogs`} as={`/blogs`}>
                <a
                    className={router.route === "/blogs" ? "active" : ""}
                    href="/blogs"
                >
                    All
                </a>
            </Link>
        </li>
        {categories.map((item, key) => {
            return (
                <li key={key}>
                    <i className="fas fa-caret-right"></i>
                    <Link
                        href={`/blogs/categories/${item.slug}-${item.id}`}
                        as={`/blogs/categories/${item.slug}-${item.id}`}
                    >
                        <a
                            className={router.asPath === `/blogs/categories/${item.slug}-${item.id}` ? 'active' : ''}
                            href={`/blogs/categories/${item.slug}-${item.id}`}
                        >
                            {item.name}
                        </a>
                    </Link>
                </li>
            );
        })}
    </ul>;
}

const BlogSearchField = ({ category_id }) => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    return <Search
        value={search}
        onChange={(e) => {
            setSearch(e.target.value)
        }}
        placeholder="Input text and tap enter to search"
        onSearch={value => {
            dispatch(fetchAllBlog({ search: value, type: 'blog', category_id: category_id || null }))
        }} />

}

const BlogFilters = ({ router, categories, category_id, showShare = false,showSearch=true }) => {
    const { asPath } = router;
    return <div>
        {showSearch ? <div className="category_box">
            <BlogSearchField category_id={category_id} />
        </div>:null}
        
        <div className="category_box">
            <CategoryLoop categories={categories} router={router} />
        </div>
        {category_id ?
            <div>

                <FacebookShareButton
                    url={`https://www.rojgarisanjal.com${asPath}`}
                >
                    <FacebookIcon size={40} round />
                </FacebookShareButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TwitterShareButton
                    url={`https://www.rojgarisanjal.com${asPath}`}
                >
                    <TwitterIcon size={40} round />
                </TwitterShareButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <LinkedinShareButton
                    url={`https://www.rojgarisanjal.com${asPath}`}
                >
                    <LinkedinIcon size={40} round />
                </LinkedinShareButton>

            </div> : null}
    </div>;
};

export { BlogFilters, CategoryLoop, BlogSearchField };