import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Input, Divider, Select } from "antd";
import { addToFavourite, favouriteGeneralStateChange } from "../../../store/actions";

const { TextArea } = Input;
const { Option } = Select;

class FavouriteModal extends Component {
    state = {
        reminder_notes: '',
        loading: false,
        group_list_id: ''
    };


    renderFavouriteTypeIntroUser(fullName, groupList) {
        return <div>
            <h3 style={{ fontSize: 18, fontWeight: 'bold', color: '#777' }}> Favourite
                ({fullName})</h3>
            <p style={{ width: '88%', marginTop: 0 }}>
                Once added to favourite, Favourite list will be saved on your dashboard <code>Dashboard &gt; My
                    Favourites</code>
            </p>
            <Divider />


            {groupList.length ?
                <Select placeholder={'Select group'} defaultValue={''} style={{ width: '100%' }} onChange={(val) => {
                    this.setState({ group_list_id: val });
                }}>
                    <Option value="">None</Option>

                    {groupList.map((item, i) => {
                        return <Option key={i} value={item.id}>{item.name}</Option>
                    })}
                </Select> : null}

            <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 20 }}>Add note
                for {fullName} (optional)</p>
        </div>
    }

    static renderFavouriteTypeIntroJob(jobName) {
        return <div>
            <h3 style={{ fontSize: 18, fontWeight: 'bold', color: '#777' }}> Favourite
                ({jobName})</h3>
            <p style={{ width: '88%', marginTop: 0 }}>
                Once added to favourite, Favourite list will be saved on your dashboard <code>Dashboard &gt; My
                    Favourites</code>
            </p>
            <Divider />

            <p style={{ fontSize: 12, fontWeight: 'bold', marginTop: 20 }}>Add note
                for {jobName} (optional)</p>
        </div>
    }


    render() {
        const { addToFavourite, user, favourite, job, group } = this.props;
        return (<Modal
            className={'auth-modal ' + (!user.data.irp ? '' : '')}
            visible={favourite.favouriteModal}
            okText="Apply"
            cancelText="Cancel">

            <div className="auth-container-x" style={{ padding: '40px 0 0 0' }}>
                {favourite.favourite_type === 'USER_MARKED_BY_COMPANY' && user.profilePage.personal ? this.renderFavouriteTypeIntroUser(user.profilePage.personal.full_name, group.groupLists) : (favourite.favourite_type === 'JOB_MARKED_BY_USER' && job.jobDetailPage.title ? FavouriteModal.renderFavouriteTypeIntroJob(job.jobDetailPage.title) : null)
                }


                <TextArea
                    rows={5}
                    value={this.state.reminder_notes}
                    onChange={(e) => {
                        this.setState({ reminder_notes: e.target.value })
                    }}
                    style={{ marginTop: 10 }}
                    placeholder="...."
                />
                <small style={{ fontSize: 14 }}>Max 190 characters</small>
                <div className="button-container" style={{ textAlign: "right", marginTop: 20 }}>
                    <Button onClick={() => {
                        this.setState({ reminder_notes: '' });
                        this.props.favouriteGeneralStateChange({ props: 'favouriteModal', value: false })
                    }}>Cancel</Button>
                    <Button disabled={this.state.reminder_notes.length > 190} loading={this.state.loading} onClick={() => {
                        this.setState({ loading: true });
                        addToFavourite(this.state.reminder_notes, this.state.group_list_id).then(res => {
                            this.setState({ reminder_notes: '' });
                        }).finally(() => {
                            this.setState({ loading: false });
                            this.setState({ reminder_notes: '' });
                        });
                    }} type="primary" style={{ marginLeft: 15 }}>Add to Favorite</Button>
                </div>
            </div>
        </Modal>);
    }

}


const mapStateToProps = state => {
    return {
        favourite: state.favourite,
        user: state.user,
        job: state.job,
        group: state.group
    }
};

const AddToFavourite = connect(mapStateToProps, { addToFavourite, favouriteGeneralStateChange })(FavouriteModal);

export { AddToFavourite }