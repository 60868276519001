import React, {Component} from 'react';
import {Icon, Input} from "antd";
import {VError} from "./../../../../src/components/common";
import PropTypes from 'prop-types';

class PasswordResetEmail extends Component {
    render() {
        const {resetState, inputChange, resetAction} = this.props;

        return (<form onSubmit={(e) => {
            e.preventDefault();
            resetAction();
        }} method="post" autoComplete="off">
            <Input
                placeholder="Enter your email"
                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                value={resetState.email}
                onChange={(e) => {
                    inputChange({props: 'email', value: e.target.value, type: 'reset'})
                }}
            />
            <VError eFor={'email'} errors={resetState.errors}/>
            <br/>
            <br/>
            <br/>

            {resetState.loader ? <button disabled={true} type={'submit'}
                                         className="btn btn-primary btn-block">
                <Icon type="loading" style={{fontSize: 20}} theme="outlined"/>
            </button> : <button type={'submit'} className="btn btn-primary btn-block">
                Forgot Password
            </button>}
        </form>);
    }
}


PasswordResetEmail.propTypes = {
    resetState: PropTypes.object.isRequired,
    inputChange: PropTypes.func.isRequired,
    resetAction: PropTypes.func.isRequired,
};


export {PasswordResetEmail}
