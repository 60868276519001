import React, {Component} from 'react';
import {Icon, Input} from "antd";
import {VError} from "./../../../../src/components/common";
import PropTypes from 'prop-types';

class PasswordReset extends Component {
    render() {
        const {resetState, inputChange, resetAction} = this.props;

        return (<form onSubmit={(e) => {
            e.preventDefault();
            resetAction();
        }} method="post" autoComplete="off">
            <Input
                type={'password'}
                placeholder="New password"
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                value={resetState.password}
                onChange={(e) => {
                    inputChange({props: 'password', value: e.target.value, type: 'reset'})
                }}
            />
            <VError eFor={'password'} errors={resetState.errors}/>
            <br/>
            <br/>
            <br/>

            <Input
                type={'password'}
                placeholder="Confirm new password"
                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                value={resetState.password_confirmation}
                onChange={(e) => {
                    inputChange({props: 'password_confirmation', value: e.target.value, type: 'reset'})
                }}
            />
            <VError eFor={'password_confirmation'} errors={resetState.errors}/>
            <br/>
            <br/>
            <br/>
            <br/>

            {resetState.loader ? <button disabled={true} type={'submit'}
                                         className="btn btn-primary btn-block">
                <Icon type="loading" style={{fontSize: 20}} theme="outlined"/>
            </button> : <button type={'submit'} className="btn btn-primary btn-block">
                Reset
            </button>}
        </form>);
    }
}


PasswordReset.propTypes = {
    resetState: PropTypes.object.isRequired,
    inputChange: PropTypes.func.isRequired,
    resetAction: PropTypes.func.isRequired,
};


export {PasswordReset}
