import React, { Component } from "react";
import ReactCrop from 'react-image-crop';
import { Button, Icon, Input, Modal, Popconfirm, Spin, Tag } from "antd";
import { sendPut } from "../../../service/request";
import { socials } from "../../../utils/helpers";
import { ExpertyFieldsCategory } from "../../common";
// import {submitJobPost} from "../../../store/actions";

let typingTimer;
var doneTypingInterval = 300;


class UserBannerInfo extends Component {
    state = {
        src: null,
        crop: {
            unit: "%",
            width: 30,
            aspect: 1,
            x: 35,
            y: 30
        },
        cropHeight: 0,
        cropWidth: 0,
        xAxis: 0,
        yAxis: 0,
        croppedImageUrl: '',
        file: '',
        showModal: false,
        uploadCroppedBlob: {},
        uploading: false,
        extension: '',
        fileName: '',
        isCompany: false,
        companyLogo: null,
        socialModal: false,
        selectedSocialKey: [],
        updateBtnEnabled: false,
        loadingSocialSave: false,
        rotate_pos: 0,
        socialValidationErrors: {},
        socials: {}
    };


    onSelectFile = (e, isCompany = false) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            this.setState({ isCompany });
            this.setState({ companyLogo: e.target.files[0] });
            this.setState({ extension: e.target.files[0].type });
            this.setState({ fileName: e.target.files[0].name });
            this.setState({ showModal: true });
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    uploadNow() {
        let uploadFile = this.state.uploadCroppedBlob;
        let endpoint = '/user/upload/profile';
        if (this.state.isCompany) {
            endpoint = '/company/upload/logo';
            //uploadFile = this.state.companyLogo;
        }

        this.setState({ uploading: true });
        sendPut(endpoint, { image: uploadFile }, {}, true, {}, true).then((res) => {
            this.setState({ showModal: false });
        }).finally(() => {
            this.setState({ uploading: false });
        });
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
            );
            this.setState({ croppedImageUrl });
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };


    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        let extension = this.state.extension;
        // let fileName = this.state.fileName;
        let fName = this.state.fileName;
        let file = canvas.toBlob((blob) => {
            blob.name = fName;
            blob.lastModifiedDate = new Date();
            let file = (new File([blob], fName, { type: extension, lastModified: Date.now() }));
            this.setState({ uploadCroppedBlob: file })
        }, extension);


        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    userBanner(props) {
        const { data } = props.user;
        const { croppedImageUrl } = this.state;
        let imageSrc = data.image;
        if (croppedImageUrl) {
            imageSrc = croppedImageUrl;
        }
        return (<div className="tr-breadcrumb bg-image section-before">
            <div className="container">
                <div className="breadcrumb-info text-center">
                    <div className="user-image">
                        <img src={imageSrc} alt="Image"
                            className="img-responsive" />
                        <div className="overlay-upload">
                            <Icon style={{ fontSize: 30 }} type="plus-circle" />
                        </div>
                        <input title={'Update your CV picture.'} onChange={(e) => {
                            this.onSelectFile(e, false);
                        }} type="file"
                            className={'user-profile-upload'} accept="image/x-png,image/gif,image/jpeg" />
                    </div>
                    {imageSrc !== 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_male.png' && imageSrc !== "https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_female.png" ? <Popconfirm
                        okType={'danger'}
                        onConfirm={() => {
                            this.props.deleteUserImage('user').then((res) => {
                                if (res.data.status === true) {
                                    this.setState({ croppedImageUrl: '' })
                                }
                            });
                        }}
                        title="Remove Image!! Are you sure？"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                        <a href="" className={'remove-icon-image'}>
                            <Icon style={{ fontSize: '11px' }} type="delete" />
                            &nbsp;<span style={{ fontSize: '11px' }}> Clear Profile Pic</span>
                        </a>
                    </Popconfirm> : null}
                    <div className="user-title">
                        <h1>{data.full_name || 'Your Full Name'}</h1>
                    </div>
                    <ul className="job-meta tr-list list-inline">
                        <li><i className="fa fa-map-marker" aria-hidden="true" />{data.p_address || 'Your address'}</li>
                        <li>
                            <i className="fa fa-phone" aria-hidden="true" />{data.phone || 'Your phone number'}
                            &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;<i className="fa fa-envelope"
                                aria-hidden="true" /><a
                                    href="#">{data.email || 'Your email'}</a>
                        </li>
                        <li>
                            <ExpertyFieldsCategory categories={data.category} />
                        </li>
                        {/*<li><i className="fa fa-briefcase"*/}
                        {/*aria-hidden="true"/>{data.expertise_title || 'Expertise title'}*/}
                        {/*</li>*/}
                    </ul>
                    {this.renderSocialList(data.social)}
                </div>
            </div>
        </div>);
    }

    companyBanner(props) {
        const { company } = props.company.item;
        let logo = company.logo;
        const { croppedImageUrl } = this.state;
        if (croppedImageUrl)
            logo = croppedImageUrl;

        return (<div className="tr-breadcrumb bg-image section-before">
            <div className="container">
                <div className="breadcrumb-info text-center">
                    <div className="user-image">
                        <img loading={'lazy'} src={logo} alt="Image"
                            className="img-responsive" />
                        <div className="overlay-upload">
                            <Icon style={{ fontSize: 30 }} type="plus-circle" />
                        </div>
                        <input title={'Update your Company Logo.'} onChange={(e) => {
                            this.onSelectFile(e, true)
                        }} type="file"
                            className={'user-profile-upload'} accept="image/x-png,image/gif,image/jpeg" />
                    </div>
                    {logo !== 'https://rojgarisanjal.sgp1.digitaloceanspaces.com/rojal/logo/logo_placeholder.jpg' ?
                        <Popconfirm
                            okType={'danger'}
                            onConfirm={() => {
                                this.props.deleteUserImage('company').then((res) => {
                                    if (res.data.status === true) {
                                        this.setState({ croppedImageUrl: '' })
                                    }
                                });
                            }}
                            title="Remove Logo!! Are you sure？"
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                            <a href="" className={'remove-icon-image'}>
                                <Icon style={{ fontSize: '11px' }} type="delete" />
                                &nbsp;<span style={{ fontSize: '11px' }}> Clear Logo</span>
                            </a>
                        </Popconfirm> : null}
                    <br />
                    <div className="user-title">
                        <h1>{company.name || 'Company Name'}</h1>
                        <p style={{ fontSize: 12 }}>{company.slogan}</p>
                    </div>
                    <ul className="job-meta tr-list list-inline">
                        <li><i className="fa fa-map-marker" aria-hidden="true" />{company.p_address || 'Company Address'}
                        </li>
                        <li>
                            <i className="fa fa-phone" aria-hidden="true" />{company.phone || 'Company Phone'}
                            &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;<i className="fa fa-envelope"
                                aria-hidden="true" /><a
                                    href="#">{company.email || 'Company Email'}</a>
                        </li>
                        <li><i className="fa fa-briefcase" aria-hidden="true" />{company.company_type || 'Company Type'}
                        </li>
                    </ul>
                    {this.renderSocialList(company.social)}
                </div>
            </div>
        </div>);
    }

    renderSocialList(currentSocial) {
        return (<ul className="breadcrumb-social social-icon-bg  tr-list">
            {Object.keys(currentSocial).map(function (key, i) {
                if (currentSocial[key])
                    if (key === 'facebook') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}><i
                                className="fab fa-facebook" /></a>
                        </li>
                    } else if (key === 'twitter') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}><i
                                className="fab fa-twitter" /></a>
                        </li>
                    } else if (key === 'youtube') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}><i
                                className="fab fa-youtube" /></a>
                        </li>
                    } else if (key === 'linkedin') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}><i
                                className="fab fa-linkedin" /></a>
                        </li>
                    } else if (key === 'whatsapp') {
                        return <li key={i}><a rel="noreferrer" href={'https://wa.me/' + currentSocial[key]} target={'_blank'}>
                            <i className="fab fa-whatsapp" style={{ backgroundColor: '#00e676' }} /></a>
                        </li>
                    } else if (key === 'whatsapp') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}>
                            <i className="fab fa-whatsapp" style={{ backgroundColor: '#00e676' }} /></a>
                        </li>
                    } else if (key === 'instagram') {
                        return <li key={i}><a rel="noreferrer" href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}>
                            <i className="fab fa-instagram" style={{ backgroundColor: '#b93973' }} /></a>
                        </li>
                    } else if (key === 'github') {
                        return <li key={i}><a rel="noreferrer" style={{ color: '#444' }}
                            href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}>
                            <i className="fab fa-github" style={{ backgroundColor: 'white', color: '#444' }} /></a>
                        </li>
                    } else if (key === 'personal') {
                        return <li key={i}><a rel="noreferrer" style={{ color: '#444' }}
                            href={'https://' + currentSocial[key].replace(/^http?.:\/\//, '')}
                            target={'_blank'}>
                            <i className="fas fa-globe" style={{ backgroundColor: 'white', color: '#444' }} /></a>
                        </li>
                    }
            })}

            {/*{Object.keys(currentSocial).length < Object.keys(socials()).length ? */}
            <li><a rel="noreferrer" href="" onClick={(e) => {
                e.preventDefault();
                this.setState({ socialModal: true })
            }} className={'add_more'}><Icon type="share-alt" />ADD SOCIAL</a></li>
            {/*: null}*/}
        </ul>);
    }

    enableButton(data) {
        // if (Object.keys(this.state.socialValidationErrors).length > 0) return true;

        const { social } = data.ica ? this.props.company.updateCopy.company : this.props.user;
        return (this.state.updateBtnEnabled === false || Object.keys(social).length === 0) || Object.keys(this.state.socialValidationErrors).length > 0;
    }

    static getSocialIcons(value) {
        switch (value) {
            case 'facebook':
            case 'linkedin':
            case 'twitter':
            case 'instagram':
            case 'youtube':
            case 'github':
                return <Icon type={value} />;
            case 'whatsapp':
                return <i className="fab fa-whatsapp" />;
            case 'personal':
                return <Icon type={'global'} />;
            default:
                return <Icon type="exclamation-circle" />;
        }
    }

    checkSocialValidation(socials) {
        const { socialValidationErrors } = this.state;
        if (typeof socials === 'object')
            Object.keys(socials).map((item) => {
                switch (item) {
                    case 'facebook':
                        const facebookUrlRegex = /^((http|https)\:\/\/)?(www.)?facebook.com\/.*/i;
                        if (socials.facebook.length && !socials.facebook.match(facebookUrlRegex)) {
                            socialValidationErrors.facebook = 'Not a valid facebook url';
                        } else {
                            delete socialValidationErrors.facebook;
                        }
                        break;
                    case 'linkedin':
                        const linkedinUrlRegex = /^((http|https)\:\/\/)?(www.)?linkedin.com\/.*/i;
                        if (socials.linkedin.length && !socials.linkedin.match(linkedinUrlRegex)) {
                            socialValidationErrors.linkedin = 'Not a valid linkedin url';
                        } else {
                            delete socialValidationErrors.linkedin;
                        }
                        break;
                    case 'twitter':
                        const twitterUrlRegex = /^((http|https)\:\/\/)?(www.)?twitter.com\/.*/i;
                        if (socials.twitter.length && !socials.twitter.match(twitterUrlRegex)) {
                            socialValidationErrors.twitter = 'Not a valid twitter url';
                        } else {
                            delete socialValidationErrors.twitter;
                        }
                        break;
                    case 'instagram':
                        const instagramUrlRegex = /^((http|https)\:\/\/)?(www.)?instagram.com\/.*/i;
                        if (socials.instagram.length && !socials.instagram.match(instagramUrlRegex)) {
                            socialValidationErrors.instagram = 'Not a valid instagram url';
                        } else {
                            delete socialValidationErrors.instagram;
                        }
                        break;
                    case 'youtube':
                        const youtubeUrlRegex = /^((http|https)\:\/\/)?(www.)?youtube.com\/.*/i;
                        if (socials.youtube.length && !socials.youtube.match(youtubeUrlRegex)) {
                            socialValidationErrors.youtube = 'Not a valid youtube url';
                        } else {
                            delete socialValidationErrors.youtube;
                        }
                        break;

                    case 'github':
                        const githubUrlRegex = /^((http|https)\:\/\/)?(www.)?github.com\/.*/i;
                        if (socials.github.length && !socials.github.match(githubUrlRegex)) {
                            socialValidationErrors.github = 'Not a valid github url';
                        } else {
                            delete socialValidationErrors.github;
                        }
                        break;
                    case 'personal':
                        const personalUrlRegex = /^[(http(s)?):\/\/(www.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;
                        if (socials.personal.length && !socials.personal.match(personalUrlRegex)) {
                            socialValidationErrors.personal = 'Not a valid url';
                        } else {
                            delete socialValidationErrors.personal;
                        }
                        break;
                    case 'whatsapp':
                        const whatsAppRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i;
                        if (socials.whatsapp.length && !socials.whatsapp.match(whatsAppRegex)) {
                            socialValidationErrors.whatsapp = 'Not a valid whatsapp number';
                        } else {
                            delete socialValidationErrors.whatsapp;
                        }
                        break;
                }
            });
        this.setState({ socialValidationErrors });
    }


    socialAccountsKinds(social, ica) {
        let socialList = ['facebook', 'linkedin', 'twitter', 'instagram', 'youtube', 'whatsapp'];
        if (!ica) {
            socialList.push("github");
            socialList.push("personal");
        }

        socialList.map((item, key) => {
            if (!social.hasOwnProperty(item)) {
                social[item] = '';
            }
        });

        return social;
    }

    updateSocialInputVal({ props, value }) {
        let { socials } = this.state;
        socials[props] = value;
        this.setState(socials);
    }

    render() {
        const { data } = this.props.user;
        const { social } = data.ica ? this.props.company.updateCopy.company : this.props.user;
        const $this = this;
        const { crop, src } = this.state;
        return <div>
            {data.ica ? this.companyBanner(this.props) : this.userBanner(this.props)}
            <Modal
                width={400}
                bodyStyle={{ padding: 0 }}
                className={'uploader-modal rotate_' + this.state.rotate_pos}
                // footer={null}
                header={null}
                centered={true}
                okText={'Upload'}
                title="Basic Modal"
                visible={this.state.showModal}
                onOk={() => {
                    this.uploadNow();
                }}
                onCancel={() => {
                    this.setState({ showModal: false });
                    this.setState({ companyLogo: '' });
                    this.setState({ extension: '' });
                    this.setState({ fileName: '' });
                    this.setState({ croppedImageUrl: '' });
                    this.setState({ src: null })
                }}>
                <Spin spinning={this.state.uploading} tip="Uploading Please wait...">
                    <div>
                        <ReactCrop
                            keepSelection={true}
                            circularCrop={true}
                            src={src}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange} />
                    </div>
                </Spin>
                <div className="rotator_crop">
                    <a rel="noreferrer" href="" onClick={(e) => {
                        e.preventDefault();
                        let rotation = this.state.rotate_pos + 90;
                        if (rotation === 360) {
                            rotation = 0;
                        }
                        this.setState({ rotate_pos: rotation })
                    }}><Icon type="retweet" /></a>
                </div>
            </Modal>

            <Modal
                className={'social_modal'}
                title="Update Social Accounts"
                visible={this.state.socialModal}
                okText={'Update Social'}
                footer={[
                    <Button key="back" onClick={() => {
                        this.setState({ socialModal: false })
                    }}>
                        Cancel
                    </Button>,
                    <Button disabled={this.enableButton(data)}
                        key="submit" type="primary"
                        loading={this.state.loadingSocialSave} onClick={() => {
                            $this.setState({ loadingSocialSave: true });
                            if (data.ica) {
                                $this.props.socialUpdate(this.state.socials).then(res => {
                                    $this.setState({ socialModal: false });
                                    $this.setState({ updateBtnEnabled: false });
                                    $this.setState({ loadingSocialSave: false });
                                });
                            } else {
                                $this.props.userSocialUpdate(this.state.socials).then(res => {
                                    $this.setState({ socialModal: false });
                                    $this.setState({ updateBtnEnabled: false });
                                    $this.setState({ loadingSocialSave: false });
                                });
                            }
                        }}>
                        Update Social
                    </Button>,
                ]}
                onOk={() => {

                }}
                onCancel={() => {
                    this.setState({ socialModal: false })
                }}>
                {Object.keys(this.socialAccountsKinds(social, data.ica)).map(function (value, i) {
                    return <div key={i}><Input onBlur={(value) => {
                    }} prefix={UserBannerInfo.getSocialIcons(value)} value={$this.state.socials[value]} onChange={(e) => {
                        if (data.ica)
                            $this.updateSocialInputVal({ props: value, value: e.target.value });

                        else
                            $this.updateSocialInputVal({ props: value, value: e.target.value });


                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(function () {
                            $this.checkSocialValidation($this.state.socials);
                        }, doneTypingInterval);

                        $this.setState({ updateBtnEnabled: true });
                    }} style={{ marginTop: 15 }}
                        placeholder={socials(value) !== 'WhatsApp' && socials(value) !== 'MyWebsite' ? (`eg: ${socials(value).toLowerCase()}.com/myprofileurl`) : (socials(value) === 'MyWebsite' ? 'mywebsite.com' : socials(value) + "'s Number")} />
                        {Object.keys($this.state.socialValidationErrors).includes(value) ? <span className={'v-error'}>
                            {$this.state.socialValidationErrors[value]}</span> : null}
                    </div>;
                })}

            </Modal>
        </div>;
    }
    componentDidMount() {
        const { data } = this.props.user;

        const { social } = data.ica ? this.props.company.updateCopy.company : this.props.user;
        this.setState({ socials: social });

    }
}


export { UserBannerInfo };

