import React from "react";
import Link from "next/link";
import { NotFound } from "../../common/NotFound";
import { Pagination } from "antd";
import { useDispatch } from "react-redux";
import { fetchAllBlog } from "../../../store/actions";
import { BlogCard } from "./BlogCard";

const BlogList = ({ data, pagination, category_id }) => {
    const dispatch = useDispatch();
    return <div className="row">
        {data.length < 1 ? <NotFound /> : null}
        {data.map((item, key) => {
            return (
                <BlogCard {...item} key={key} />
            );
        })}
        <div className={'col-12'}>
            {pagination && pagination.totalCount > 6 ? (
                <Pagination
                    pageSize={6}
                    current={pagination.page}
                    defaultCurrent={1}
                    total={pagination.totalCount}
                    onChange={(page, limit) => {
                        dispatch(fetchAllBlog({ category_id, page, type: 'blog' }));
                    }}
                />
            ) : null}
        </div>
    </div>

};

export { BlogList };