import {AppModal} from "../../common";
import React from "react";
import PropTypes from "prop-types";
import {redirect} from "../../../utils/redirect";

const QuickSearch = (props) => {
    const {onClose, show, jobFilter, job} = props;
    return <AppModal onClose={onClose} show={show}>
        <h3>Search Jobs</h3>
        <br/>
        <div className="banner-content text-center">
            <div className="banner-form">
                <form action="#">
                    <input type="text" onChange={(e) => {
                        jobFilter({
                            props: 'search',
                            value: e.target.value,
                            searchNow: false
                        })
                    }} className="form-control" placeholder="Job Keyword"/>
                    <div className="dropdown tr-change-dropdown">
                        <input type="text" onChange={(e) => {
                            jobFilter({
                                props: 'address',
                                value: e.target.value,
                                searchNow: false
                            });
                        }} className="form-control" placeholder="Location"/>
                    </div>
                    <button onClick={(e) => {
                        e.preventDefault();
                        if (job.filter.address || job.filter.search){
                            redirect('/vacancies');
                            onClose();
                        }
                    }} type="submit" className="btn btn-primary" value="Search">
                        Search
                    </button>
                </form>
            </div>
        </div>
    </AppModal>
};

QuickSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    jobFilter: PropTypes.func.isRequired
};


export {QuickSearch};