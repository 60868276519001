import React from "react";
import Link from "next/link";

const BlogCard = ({ image, title, short_desc, slug, id, isGovernmentJobs = false, column = 'col-lg-6 col-md-6' }) => {
    return (
        <div className={column}>
            <div className="blog_grid_box">
                <figure>
                    <img
                        src={`${image}`}
                        onError={(e) => {
                            e.target.src =
                                "https://www.worldloppet.com/wp-content/uploads/2018/10/no-img-placeholder.png";
                        }}
                        className="img-fluid"
                        alt="Responsive image"
                    />
                </figure>
                <div className="blog_grid_content">
                    <h1 title={title}>
                        {title.substr(0, 30)}{" "}
                        {title.length > 30 ? "..." : ""}
                    </h1>
                    <p>{short_desc.substr(0, 100)}...</p>
                    <Link
                        href={isGovernmentJobs === false ? `/blogs/details/${slug}-${id}` : `/government-jobs/details/${slug}-${id}`}
                        as={isGovernmentJobs === false ? `/blogs/details/${slug}-${id}` : `/government-jobs/details/${slug}-${id}`}
                    >
                        <a
                            href={isGovernmentJobs === false ? `/blogs/details/${slug}-${id}` : `/government-jobs/details/${slug}-${id}`}
                            className="btn btn-success btn-block view_more"
                        >
                            View Details
                        </a>
                    </Link>
                </div>
            </div>
        </div>)


};

export { BlogCard };