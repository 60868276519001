import React from 'react';
import PropTypes from 'prop-types';
import { concatCategories, slugify, ucFirst, ucWords } from "../../../utils/helpers";
import { NotFound } from "../../common/NotFound";
import { Button, Dropdown, Icon, Modal, Menu, Popover } from "antd";
import { ExpertyFieldsCategory } from "../../common";
import { connect } from "react-redux";
import { removeFavourite, updateFavourite } from "../../../store/actions";
import { redirect } from '../../../utils/redirect';

const { confirm } = Modal;
const { SubMenu } = Menu;

const candidateActionButton = (jobGeneralState, userDetails, shortListCandidate, item, selectedDeadLineId) => {
    return <div className="view-profile">
        <div className="row">
            <div className="col-6">
                <Button onClick={() => {
                    jobGeneralState({ props: 'selectedApplicant', value: item });
                    userDetails(item.id, {}, true);
                }}><Icon type="user" /> Profile</Button>
            </div>
            <div className="col-6 d-flex justify-content-end">

                {item.save_type !== 1 ? <Button type="primary" onClick={() => {
                    if (shortListCandidate) {
                        shortListCandidate(item.id, selectedDeadLineId);
                    }
                }}><Icon type="check-circle" />
                        Shortlist</Button> :
                    <Button onClick={() => {
                        if (shortListCandidate) {
                            confirm({
                                title: 'Decline shortlisted candidate?',
                                content: 'Click the Yes button if you want to remove this candidate from shortlisted group',
                                okText: 'Yes',
                                cancelText: 'No',
                                onOk() {
                                    shortListCandidate(item.id, selectedDeadLineId);
                                }
                            });
                        }
                    }} type="danger"><Icon type="close-circle" />
                        Decline</Button>}
            </div>
        </div>
    </div>;
};

const favouriteListActionButton = (removeFavourite, item, userDetails) => {
    return <div className="view-profile">
        <div className="row">
            <div className="col-6">
                <Button onClick={() => {
                    userDetails(item.id, {}, true);
                    // jobGeneralState({props: 'selectedApplicant', value: item});
                    // userDetails(item.id, {}, true);
                }}><Icon type="user" /> Profile</Button>
            </div>
            <div className="col-6 d-flex justify-content-end">

                <Button type="danger" onClick={() => {
                    confirm({
                        title: 'Remove from favourite list?',
                        content: 'Click the Yes button if you want to remove this candidate from your favourite list',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk() {
                            removeFavourite(item.favourite_id)
                        }
                    });
                }}><Icon type="delete" />
                    Remove</Button>
            </div>
        </div>
    </div>;
};


const UserCardComponent = ({ users, isMine, column = 'col-lg-4', jobGeneralState, userDetails, shortListCandidate = '', selectedDeadLineId = '', openUserDetails = null, cardType = null, removeFavourite, showFavouriteActionMenu, group, updateFavourite, isSlim = false }) => {
    if (!users.length)
        return <NotFound />;

    return users.map((item, i) => {
        return (<div key={i} className={`${column} col-md-6`} style={{ cursor: isSlim ? 'pointer' : 'default' }} onClick={() => {
            if (isSlim)
                redirect('/profile/[userId]', {}, `/profile/${concatCategories(item.category, true)}-${item.id}`, true);
        }}>
            <div className="job-grid-view user-profile-grid">
                <div className="about-user">
                    <div className="user-img-grid">
                        <div className="img-holder">
                            <img src={item.image} loading={'lazy'} alt={ucWords(item.full_name)} onError={(e) => {
                                let userDefaultImage = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_male.png';
                                if (item.gender === 'female') {
                                    userDefaultImage = 'https://rojal.s3.ap-south-1.amazonaws.com/defaults/profile_female.png';
                                }
                                e.target.src = userDefaultImage;
                            }} />
                        </div>
                    </div>
                    <div className="user-info d-flex justify-content-between">
                        <div>
                            <h2 className={'user-title'}>{ucWords(item.full_name) || '-'}</h2>

                            <div className={'expertise_field'}>
                                <ExpertyFieldsCategory separater={'/'} categories={item.category} icon={false} />
                            </div>
                            {isSlim ? <p style={{ marginTop: 10 }}><Icon type="home" /> {item.p_address ? item.p_address.substr(0, 16) : '-'}{item.p_address && item.p_address.length > 16 ? '...' : null}</p> : null}

                        </div>
                        {showFavouriteActionMenu ? <div>
                            <Dropdown overlay={<Menu>
                                {item.reminder_notes && item.reminder_notes.length ? <Menu.Item>
                                    <Popover className="ant_popover_custom_class" style={{ width: '300px!important' }} content={<div>{item.reminder_notes}</div>} title="Note">
                                        <a target="_blank" href="" onClick={(e) => {
                                            e.preventDefault();
                                        }}>
                                            View Note
                                        </a>
                                    </Popover>
                                </Menu.Item> : null}
                                <SubMenu title="Change Group">
                                    {group.groupLists.map((grp, keyX) => {
                                        if (grp.id !== item.group_list_id)
                                            return <Menu.Item onClick={() => {
                                                confirm({
                                                    title: 'Change Group',
                                                    content: 'Are you sure you want to move this user to the selected group',
                                                    okText: 'Move',
                                                    cancelText: 'Cancel',
                                                    onOk() {
                                                        updateFavourite(item.favourite_id, grp.id);
                                                    }
                                                });
                                            }} key={keyX}>{grp.name}</Menu.Item>
                                    })}
                                </SubMenu>
                            </Menu>}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {/*<Icon style={{fontSize: 18}} type="down"/>*/}
                                    <i style={{ fontSize: 18 }} className="fas fa-chevron-down" />
                                </a>
                            </Dropdown>
                        </div> : null}
                    </div>
                </div>
                {!isSlim ? <div className="user-job-info">
                    <div className="u-basic-info">
                        <div title={item.p_address || ''} className="b-info">
                            <p>Address</p>
                            <h3>{item.p_address ? item.p_address.substr(0, 16) : '-'}{item.p_address && item.p_address.length > 16 ? '...' : null}</h3>
                        </div>
                        <div className="b-info">
                            <p>CV Last Updated</p>
                            <h3>{ucFirst(item.updated_time.human) || '-'}</h3>
                        </div>
                    </div>
                </div> : null}


                {!isSlim ? <div className="grid-footer">
                    {isMine ? candidateActionButton(jobGeneralState, userDetails, shortListCandidate, item, selectedDeadLineId) : (cardType === 'MY_FAVOURITE_LIST' ? favouriteListActionButton(removeFavourite, item, userDetails) :
                        <div className="view-profile">
                            <a onClick={(e) => {
                                e.preventDefault();
                                if (openUserDetails) {
                                    openUserDetails(`/profile/${concatCategories(item.category, true)}-${item.id}`, '/profile/[userId]', true);
                                }
                            }} href="" className={'btn btn-default btn-block'}>
                                Profile
                            </a>
                        </div>)}
                </div> : null}
            </div>


        </div>);
    });
};

const mapStateToProps = (state) => {
    return {
        group: state.group
    }
};
UserCardComponent.prototype = {
    users: PropTypes.array.isRequired
};


const UserCard = connect(mapStateToProps, { removeFavourite, updateFavourite })(UserCardComponent);

export { UserCard };