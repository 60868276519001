import React, { Component } from 'react';
import moment from 'moment';
import { jobExperienceType, salaryParser, ucFirst } from "../../../utils/helpers";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import { Divider } from "antd";

class JobPost extends Component {
    jobLabel = {
        1: 'Intern',
        3: 'Mid',
        2: 'Junior',
        4: 'Senior'
    };
    state = {
        visible: false,
        loading: false,
        declarations: ''
    };


    render() {
        const { job, isTemp } = this.props;
        return (
            <div className="job-summary section">
                <span id="title"
                    className="tr-title"><strong>{job.title ? job.title.toUpperCase() : '-'}</strong> </span>
                <hr />
                <div style={{ overflowX: 'auto' }} id="scrolling_table">
                    <table className="table-responsives">
                        <tbody>
                            <tr>
                                <th>Jobs Function:</th>
                                <td id="sub_category_id">{job.category ? job.category.name : '-'}</td>
                                <th id="availability">Jobs Availability:</th>
                                <td>{job.availability === "0" ? 'Part Time' : job.availability === "1" ? 'Full Time' : job.availability === "2" ? 'Freelance' : '-'}</td>
                            </tr>
                            <tr>
                                <th id="post_label">Jobs Level:</th>
                                <td>{this.jobLabel[job.post_label]}</td>
                                <th id="salary">Offered Salary:</th>
                                <td>{salaryParser(job.salary, job.salary_to)}</td>
                            </tr>
                            <tr>
                                <th id="job_location">Jobs Location:</th>
                                <td>{job.job_location}</td>
                                <th id="experience_year">Min. Experience Year:</th>
                                <td>{job.experience_year ? jobExperienceType(job.experience_year) : '-'}</td>
                            </tr>
                            <tr>
                                <th id="education">Academic Qualification:</th>
                                <td>{job.education}</td>
                                <th>No. of Openings:</th>
                                <td id="openings">{job.openings || '-'}</td>
                            </tr>
                            <tr>
                                <th>Deadline:</th>
                                <td id="vacancy_deadline">
                                    {job.vacancy_deadline ? moment(job.vacancy_deadline).format('LL') + ' (' + ucFirst(moment(job.vacancy_deadline).fromNow()) + ')' : '-'}
                                </td>
                                <th>Preferred Gender:</th>
                                <td id="preferred_gender">{ucFirst(job.preferred_gender)}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>


                <span className="tr-title"><strong>JOB DESCRIPTION</strong> </span>
                <hr />
                <div id="descriptions">
                    {job.descriptions.map(function (item, i) {
                        return <div key={i} style={{ fontSize: 15, display: 'flex' }}>
                            <i
                                className="far fa-hand-point-right pr-2 pt-1"
                                aria-hidden="true" />
                            &nbsp;&nbsp;{item}
                        </div>
                    })}
                </div>
                <br />

                <span className="tr-title"><strong>JOB REQUIREMENTS</strong> </span>
                <hr />
                <div id="requirements">
                    {job.requirements.map(function (item, i) {
                        return <div key={i} style={{ fontSize: 15, display: 'flex' }}><i
                            className="far fa-hand-point-right pr-2 pt-1"
                            aria-hidden="true" />
                            {item}
                        </div>
                    })}
                </div>

                <br />

                <Divider orientation="left">
                    <small style={{ color: '#666', fontSize: 14 }}>Share Job Post</small>
                </Divider>

                {!isTemp ? <div>
                    <FacebookShareButton quote={job.title} hashtag={'#FreeJobPostRojgarisanjal'}
                        url={`https://www.rojgarisanjal.com/job/detail/${job.slug}`}>
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TwitterShareButton quote={job.title} hashtag={'#FreeJobPostRojgarisanjal'}
                        url={`https://www.rojgarisanjal.com/job/detail/${job.slug}`}>
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <LinkedinShareButton quote={job.title} hashtag={'#FreeJobPostRojgarisanjal'}
                        url={`https://www.rojgarisanjal.com/job/detail/${job.slug}`}>
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>
                </div> : null}
            </div>

        );
    }
}


export { JobPost };