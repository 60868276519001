import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    authInputChange,
    authModelSwitch,
    signIn,
    signUp,
    authScreenSwitch,
    socialAuthentication
} from "../../../../src/store/actions";
import {LoginForm} from "./LoginForm";
import {RegisterForm} from "./RegisterForm";
import {Modal} from "antd";

class AuthModel extends Component {

    renderLoginForm() {
        return (<div>
            <LoginForm authModelSwitch={this.props.authModelSwitch}
                       authScreenSwitch={this.props.authScreenSwitch}
                       signInActionSocial={this.props.socialAuthentication}
                       loginState={this.props.auth.signIn}
                       inputChange={this.props.authInputChange}
                       signInAction={this.props.signIn}/>
        </div>);
    }

    renderSignUpForm() {
        return (<div>
            <RegisterForm authScreenSwitch={this.props.authScreenSwitch}
                          signUpState={this.props.auth.signUp}
                          inputChange={this.props.authInputChange}
                          signUpAction={this.props.signUp}/>
        </div>);
    }

    render() {
        const {authSwitch} = this.props.auth;
        return (<div className={'auth-modal'}>
            <Modal
                width={650}
                className={'auth-modal auth-form'}
                centered
                visible={this.props.auth.authModel}
                onCancel={() => {
                    this.props.authModelSwitch(false);
                }}>
                <div className="auth-container" style={{padding: '10px 0'}}>
                    <div className="login-input-box">
                        {authSwitch ? this.renderLoginForm() : this.renderSignUpForm()}
                    </div>
                </div>
            </Modal>
        </div>);
    }

    componentDidMount() {
        //the model some how does not gets closed when the screen is changes hence this is a
        //polyfill the close the model manually.
        let $this = this;
        document.addEventListener('keyup', function (e) {
            if (e.key === "Escape") { // escape key maps to keycode `27`
                $this.props.authModelSwitch(false);
            }
        })
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

export default connect(mapStateToProps, {
    authInputChange,
    signIn,
    authModelSwitch,
    socialAuthentication,
    signUp,
    authScreenSwitch
})(AuthModel);
