import React, { useState } from "react";

import Link from "next/link";
import { BackTop } from "antd";
import { feedbackGenerateState, subscribeAction } from "../../../store/actions";
import { connect } from "react-redux";
import { validEmail } from "../../../utils/helpers";
import { openNotificationWithIcon } from "../../../utils/notification";

const AppFooterComp = (props) => {
    let { footerExtraPadding, authCheck, feedbackGenerateState, subscribeAction } = props;
    if (!authCheck) footerExtraPadding = 0;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)
    return (<div className="footer">
        <footer>
            <div className="container">
                <div className="footer-section">
                    <div className="row">
                        <div className="col-md-12 col-lg-5 mb-3">
                            <div className="single-secton">
                                <h3>Subscribe Our Newsletter</h3>
                                <div className="form">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        subscribeAction({ email }).then(res => {
                                            setEmail('');
                                            openNotificationWithIcon('success', {
                                                title: 'Yeey!! Subscribed Successfully',
                                                description: res.data.msg
                                            });
                                        }).catch(e => {
                                            console.log(e);
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    }}>
                                        <div className="form-group">
                                            <input value={email} onChange={(e) => {
                                                setEmail(e.target.value);
                                            }} placeholder="Enter your Email" id="exampleInputName1"
                                                className="form-control first" type="text" />
                                        </div>
                                        <input onClick={() => {
                                            setLoading(true);
                                            subscribeAction({ email }).then(res => {
                                                setEmail('');
                                                openNotificationWithIcon('success', {
                                                    title: 'Yeey!! Subscribed Successfully',
                                                    description: res.data.msg
                                                });
                                            }).catch(e => {
                                                console.log(e);
                                            }).finally(() => {
                                                setLoading(false);
                                            });
                                        }} disabled={!validEmail(email) || loading} className="bttn" style={{ color: 'white' }} type="submit" value="Subscribe" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-3">
                            <div className="single-secton">
                                <h3>Follow us on</h3>
                                <ul>
                                    <li className="facebook">
                                        <a rel="noreferrer" target={"_blank"}
                                            href="https://www.facebook.com/rojgarisanjal/">
                                            <i className="fab fa-facebook" />
                                        </a>
                                    </li>
                                    <li className="twitter">
                                        <a target={"_blank"} rel="noreferrer" href="https://twitter.com/RojgariS">
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li className="linkedin">
                                        <a target={"_blank"}
                                            rel="noreferrer"
                                            href="https://www.linkedin.com/company/rojgari-sanjal/">
                                            <i className="fab fa-linkedin" />
                                        </a>
                                    </li>
                                    <li className="instagram">
                                        <a target={"_blank"} rel="noreferrer"
                                            href="https://www.instagram.com/rojgarisanjal/">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4">
                            <div className="single-secton">
                                <h3>Need Help ?</h3>
                                <p><i className="fa fa-phone" /> Call : <span>9851316217</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="single-section-two white">
                            <h3>Coming Soon </h3>
                            <p>
                                <img src="https://rojal.s3.ap-south-1.amazonaws.com/rojal/defaults/app_ios_android.png" alt="" />
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="single-section-two white">
                            <h3>Company</h3>
                            <ul>
                                <li><Link href={'/about'}><a href="/about">About Rojgari Sanjal</a></Link></li>
                                <li><Link href={'/terms-conditions'}><a href="/terms-conditions">Terms & Conditions</a></Link>
                                </li>
                                <li><Link href={'/privacy'}><a href="/privacy">Privacy Policy</a></Link></li>
                                <li><Link href={'/faq'}><a href="/faq">Frequently Ask Question</a></Link></li>
                                <li><Link href={'/contact'}><a href="/contact">Contact Us</a></Link></li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <div className="single-section-two white">
                            <h3>Useful Links</h3>
                            <ul>
                                <li>
                                    <Link href={'/blogs'}>
                                        <a href="/blogs">Rojgari Sanjal Blog</a>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#">
                                        Create Resume
                                    </a>
                                </li>
                                <li>
                                    <Link href={'/government-jobs'}>
                                        <a href="/government-jobs">
                                            Government Jobs
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={'/education-and-training'}>
                                        <a href="/education-and-training">
                                            Educations & Trainings
                                        </a>
                                    </Link>
                                </li>
                                <li><a href="" onClick={(e) => {
                                    e.preventDefault();
                                    feedbackGenerateState({ props: 'feedbackModal', value: true })
                                }}>Feedback & Suggestions</a></li>
                                <li><Link href={'/sitemap'}><a href="/sitemap">Site Map</a></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <div className="single-section-two white">
                            <h3>Keep In Touch</h3>
                            <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frojgarisanjal%2F&tabs=timeline&width=300&height=180&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2234768930107771"
                                width="99%" height="180" style={{ border: "none", overflow: "hidden" }} scrolling="no"
                                frameBorder="0" allowtransparency="true" allow="encrypted-media" />

                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div
            style={{ marginBottom: footerExtraPadding || 0 }}
            className="copyright-wrapper mb-lg-0 mb-5">
            <div
                className="container">
                <p> &copy; {(new Date).getFullYear()} Copyright rojgarisanjal.com (v2.03) | All Rights
                    Reserved.
                </p>
            </div>
        </div>

        <BackTop />
    </div>);
};
const mapStateToProps = (state) => {
    return {
        feedback: state.feedback
    }
};

const AppFooter = connect(mapStateToProps, {
    feedbackGenerateState,
    subscribeAction
})(AppFooterComp);


export { AppFooter }